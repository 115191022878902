import * as React from "react"
import {graphql} from "gatsby";
import {Col, Row} from "react-bootstrap";
import RenderBlocks from "../components/renderBlocks";
import ProfileImage from '../assets/media/personal-profile.jpg';
import SEO from "../components/seo";

export const query = graphql`
    query AboutPageQuery {
        datoCmsPage(url: { eq: "about" }) {
            title
            content {
                ... on DatoCmsBasicContent {
                    id
                    model {
                        apiKey
                    }
                    content
                }
                ... on DatoCmsTag {
                    id
                    title
                    model {
                        apiKey
                    }
                    skills {
                        title
                    }
                }
            }
        }
    }
`

const AboutPage = ({data}) => {
    const {
        datoCmsPage: {
            content
        }
    } = data

    return (
        <>
            <SEO title={'About'} />

            <div className={'about-page'}>
                <div className={'container mb-5'}>
                    <Row className={`side-by-side image-mobile-left content-left`}>
                        <Col lg={8} className={`col-12`}>
                            <RenderBlocks fields={content}/>
                        </Col>

                        <div className={`col-12 col-lg-4 mb-4 mb-lg-0 d-flex justify-content-lg-center mt-0 mt-lg-5`}>
                            <img src={ProfileImage} alt='kartik patel' className={'profile-image'}
                                 style={{borderRadius: '15px'}}/>
                        </div>
                    </Row>
                </div>
                <div className={'gray-bottom'}/>
            </div>
        </>
    )
}

export default AboutPage